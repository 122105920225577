import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import "../css/pages/wisdom-for-wisdom.scss"

const W4WPage = () => {
  const CLIENT = "Utah Surgical Arts"
  const LOCAL_STUDENTS = "one local student"
  const CITY = "South Jordan, Provo, Payson, or Lehi"
  const APPLICATION_PERIOD = "June 3, 2021"

  const WISDOM_FACTS = [
    "9 out of 10 people will have at least one wisdom tooth that is impacted.",
    "Wisdom teeth are also known as third molars.",
    "Wisdom teeth often appear between the ages of 17 and 25.",
    "Wisdom teeth are the last of the 32 adult permanent teeth to develop and erupt.",
    "Wisdom teeth are removed for a variety of reasons, including the jaw not having the space for them."
  ]

  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />
      <div className="w4w-main">
        <div className="w4w-header">
          <img src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/w4w-logo-2021.svg" />
        </div>
        <div className="w4w-top-container">
          <div className="w4w-image">
            <img src="https://res.cloudinary.com/nuvolum/image/upload/c_scale,w_1121/v1/Programs/w4w-hero-image-2021.jpg" />
          </div>
          <img
            className="w4w-logo"
            src="https://res.cloudinary.com/nuvolum/image/upload/v1/Programs/w4w-logo-mark-2021.jpg"
          />
          <div className="image-overlay">
            <img
              className="w4w-left is-hidden-mobile"
              src="https://res.cloudinary.com/nuvolum/image/upload/v1620235157/Programs/w4w-left-section-2021-close.png"
            />
            <img
              className="w4w-left is-hidden-tablet"
              src="https://res.cloudinary.com/nuvolum/image/upload/c_scale,w_280/v1618849555/Programs/w4w-left-section-2021-mobile.png"
            />
          </div>
          {/* <p className="w4w-top-container__text is-hidden-tablet">
            Apply for a chance to receive free wisdom teeth removal.
          </p> */}
        </div>
        <div className="w4w-mid-container">
          <div className="list-container">
            <div className="facts">
              <h3 className="list-header">Did you know...</h3>
              <ul className="wisdom-facts">
                {WISDOM_FACTS.map((fact, i) => (
                  <li key={i + "-fact"}>• {fact}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mid-paragraph">
            <p className="mid-text">
              {CLIENT} Wisdom For Wisdom program provides free wisdom teeth
              removal procedures to local students, helping to offset some of
              their college expenses, like textbooks.
            </p>

            <p>
              This year our recipients are Cody from West Jordan, Steffanie from
              Payson, and Kate from South Jordan.
            </p>

            <p>
              Cody, 17, just graduated from high school and plans to attend Salt
              Lake Community College. In his application he wrote about how he
              was working toward completing his Diesel Technician degree while
              also completing high school and playing baseball. He hopes this
              program will help him pay for the rest of his degree so that he
              can start his career.
            </p>
            <p>
              Steffanie, 18, graduated from Salem Hills High School and plans to
              attend Dixie State University in St. George. In applying for our
              Wisdom For Wisdom program she said that she was a first generation
              college student and worries about all of the costs associated with
              going to college. While finishing high school she has also been
              part of the medical assisting program at MTECH and working at
              Elevate Health Sciences. She said that her work has helped her
              save enough to pay for her first year of housing and that her real
              goal is to make her immigrant parents proud as she goes to college
              and on to medical school.
            </p>
            <p>
              Kate, 17, is a student at Paradigm High School and hopes to attend
              the University of Utah. In her application, she mentioned that her
              parents have had to put her wisdom teeth removal procedure off as
              they try to save money. She also mentioned that her orthodontist
              warned her that if she wasn’t able to get them removed, it could
              undo her years in braces by pushing her other teeth out of
              alignment.
            </p>
            <p>
              Follow Cody’s, Steffanie's, and Kate’s treatment journeys on{" "}
              <a
                href="https://www.facebook.com/utahsurgicalartsoms"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/utahsurgicalarts/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>
              .{" "}
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link to="/wisdom-teeth-removal-south-jordan-ut/">
                  wisdom teeth removal
                </Link>{" "}
                and all of our <Link to="/procedure/">procedures</Link>.{" "}
              </i>
            </p>
          </div>

          {/* <TimedElement
            endDate="4 June 2021 09:00:00 EDT"
          >
          <div className="apply-container">
            <h3>
              Applying is as <span style={{ fontWeight: 800 }}>easy</span> as{" "}
              <span style={{ fontWeight: 800 }}>1, 2, 3!</span>
            </h3>
            <div className="apply-content">
              <div className="one-two-three-wrapper">
                <div className="hl"></div>
                <div className="one-two-three">
                  <div className="one-two-three__item">1</div>
                  <div className="one-two-three__item">2</div>
                  <div className="one-two-three__item">3</div>
                </div>
              </div>
              <div className="steps">
                <div>Watch the video.</div>
                <div className="long">
                  <span>
                  Take the quiz.
                  </span>
                </div>
                <div>Complete your application.</div>
              </div>
            </div>
          </div>
          </TimedElement>
        </div>
        <div className="w4w-bottom-container">
        <TimedElement
            endDate="4 June 2021 09:00:00 EDT"
          >
          <div className="w4w-sub-paragraph">
            <h3>
              We wish every student the best as they pursue their academic
              endeavors.
            </h3>
          </div>
          <div className="w4w-button">
            <Button
              buttonText="STEP 1: WATCH THE VIDEO"
              href="/wisdom-step-1/"
              contained
            ></Button>
          </div>
          </TimedElement> */}
        </div>
        {/* <section className="application-section color-back">
        <div className="columns has-text-centered">
          <div className="column">
              <h2>Wisdom for Wisdom Application</h2>
            </div>
          </div>
            <div className="columns">
            <div className="column is-6"></div>
            <div className="column">

           
            <div className="application-wrapper"><Seamless src="/w4wContactForm.js" /></div>
            </div>
            <div className="column is-6"></div>
            </div>

            
          </section> */}
      </div>
    </Layout>
  )
}

export default W4WPage
